.dropshadow-md{
    box-shadow: -2px 10px 20px -4px rgba(0,0,0,0.10);
    -webkit-box-shadow: -2px 10px 20px -4px rgba(0,0,0,0.10);
    -moz-box-shadow: -2px 10px 20px -4px rgba(0,0,0,0.10);
}

body{
    margin: 0;
}

.right{
    margin-left: auto;
    display: flex;
}

.bold > th{
    font-weight: bold;
}
.table-color{
    background-color: transparent;
    border: none;
}
